import { mixins } from "@/plugins/mixins";
import { ElLoading, ElMessage } from "element-plus";
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'

export default {
  name: "customer",
  mixins: [mixins],
  data() {
    return {
      makeForm: {
        id: null,
        memo: null
      },
      makeDialog: false,
      columns: [{
        label: '车主电话',
        prop: 'customerTel'
      }, {
        label: '意见类型',
        prop: 'suggestTypeDesc'
      }, {
        label: '意见时间',
        prop: 'createTime'
      }, {
        label: '意见内容',
        prop: 'suggest'
      }, {
        label: '状态',
        prop: 'suggestStatus'
      }, {
        label: '处理时间',
        prop: 'makeTime'
      }, {
        label: '处理人',
        prop: 'makeUserName'
      }, {
        label: '处理结果',
        prop: 'makeMemo'
      }],
      formRules: {
        memo: [{
          required: true,
          message: '请输入处理结果',
          trigger: 'blur'
        }, {
          max: 100,
          message: '处理结果长度不能超过100',
          trigger: 'blur'
        }]
      },
      linkButtons: [{
        name: '处理',
        code: 'customerSuggestInfo_make',
        click: this.make,
        permissions: ['customerSuggestInfo_make'],
        show: row => {
          return row.suggestStatus !== '已处理';
        }
      }],
      searchItems: [{
        prop: 'phone',
        label: '联系电话'
      }, {
        prop: 'suggestStatus',
        label: '状态',
        type: 'select',
        items: ['待处理', '已处理']
      }]
    };
  },
  methods: {
    make(row) {
      this.makeDialog = true;
      this.makeForm = {
        id: row.id,
        memo: null
      };
    },
    doMake() {
      const loading = ElLoading.service();
      const callBack = res => {
        if (res.code === 200) {
          ElMessage({
            message: res.message,
            type: 'success'
          });
          this.$refs.tablePage.fetch();
          this.makeDialog = false;
        } else {
          ElMessage.error(res.message);
        }
      };
      this.$api.business.customerSuggestInfo.make(this.makeForm).then(callBack).finally(() => loading.close());
    }
  }
};